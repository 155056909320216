<script setup lang="ts">
import { cn } from "@/lib/utils";
import type { LucideIcon } from "lucide-vue-next";
import { ChevronDown, ChevronUp } from "lucide-vue-next";
import { computed } from "vue";
import type { RouteRecordName } from "vue-router";
import { useRouter } from "vue-router";

interface SubLink {
    title: string;
    routeName: Exclude<RouteRecordName, null | undefined>;
    params?: Record<string, string>;
}

interface BaseLink {
    title: string;
    icon?: LucideIcon;
    routeName?: Exclude<RouteRecordName, null | undefined>;
    params?: Record<string, string>;
    isDropdown?: boolean;
    subLinks?: SubLink[];
}

interface Props {
    link: BaseLink;
    isServicesDropdownOpen: boolean;
    isMembersDropdownOpen: boolean;
    toggleServicesDropdown: () => void;
    toggleMembersDropdown: () => void;
    isLinkActive: (routeName: RouteRecordName, params?: Record<string, string>) => boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
    (e: "close"): void;
}>();

const router = useRouter();

const isDropdownOpen = computed(() =>
    props.link.title === "Services" ? props.isServicesDropdownOpen : props.isMembersDropdownOpen,
);

const handleLinkClick = (routeName: RouteRecordName, params?: Record<string, string>) => {
    router.push({ name: routeName, params });
    emit("close");
};

const handleDropdownToggle = () => {
    if (props.link.title === "Services") {
        props.toggleServicesDropdown();
    } else {
        props.toggleMembersDropdown();
    }
};

const isOneOfMySublinksActive = computed(() => {
    if (!props.link.subLinks) return false;
    return props.link.subLinks.some(subLink => props.isLinkActive(subLink.routeName, subLink.params));
});
</script>

<template>
    <template v-if="link.isDropdown">
        <div @click="handleDropdownToggle" class="cursor-pointer">
            <div class="group flex flex-row flex-nowrap items-center gap-2 text-white">
                <div
                    :class="isOneOfMySublinksActive ? 'bg-secondary' : 'bg-transparent'"
                    class="size-9 overflow-visible rounded-full p-2 transition-colors">
                    <component :is="link.icon" class="size-5" />
                </div>

                {{ link.title }}

                <component :is="isDropdownOpen ? ChevronUp : ChevronDown" class="size-4" />
            </div>
        </div>
        <ul v-if="isDropdownOpen" class="ml-11 mt-2 space-y-2">
            <li v-for="subLink in link.subLinks" :key="subLink.title">
                <router-link
                    :to="{ name: subLink.routeName, params: subLink.params }"
                    @click.prevent="handleLinkClick(subLink.routeName, subLink.params)"
                    class="group flex items-center gap-2 text-white">
                    <div
                        :class="
                            props.isLinkActive(subLink.routeName, subLink.params) ? 'bg-secondary' : 'bg-transparent'
                        "
                        class="group-hover:bg-secondary/60 size-2 rounded-full transition-colors" />
                    <span
                        :class="
                            cn(
                                'group-hover:text-secondary/60 transition-colors',
                                props.isLinkActive(subLink.routeName, subLink.params) ? 'text-secondary' : 'text-white',
                            )
                        ">
                        {{ subLink.title }}
                    </span>
                </router-link>
            </li>
        </ul>
    </template>
    <router-link
        v-else-if="link.routeName"
        :to="{ name: link.routeName, params: link.params }"
        @click.prevent="handleLinkClick(link.routeName, link.params)"
        class="group flex flex-row flex-nowrap items-center gap-2 text-white">
        <component
            :is="link.icon"
            :class="
                link.routeName && props.isLinkActive(link.routeName, link.params)
                    ? 'bg-secondary rounded-full'
                    : 'bg-transparent'
            "
            class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full p-2 transition-colors" />

        {{ link.title }}
    </router-link>
</template>
