import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { api } from "@/lib/api";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { handleFetchError } from "./error-handler";

export interface ApiErrorResponse {
    message: string;
    statusCode: number;
    error: string;
    [key: string]: any;
}

class ApiError extends Error {
    statusCode: number;
    details: any;

    constructor(message: string, statusCode: number, details?: any) {
        super(message);
        this.name = "ApiError";
        this.statusCode = statusCode;
        this.details = details;
    }
}

interface FetchWithOrgHeaderOptions extends RequestInit {
    forceOrganizationId?: string;
    returnUndefinedOn201?: boolean;
    skipAuthRefresh?: boolean;
}

export const fetchWithOrgHeader = async <T = any>(url: string, options: FetchWithOrgHeaderOptions = {}): Promise<T> => {
    const { forceOrganizationId, returnUndefinedOn201, skipAuthRefresh = false, ...fetchOptions } = options;
    const authStore = useAuthStore();
    const { isSystemAdmin, isSystemAdminOrganisation } = useUserPrivileges();
    const { getAccessToken } = authStore;
    const { selectedOrganization } = storeToRefs(authStore);

    const headers = new Headers(fetchOptions.headers);
    const accessToken = getAccessToken();

    if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
    }

    const orgId = forceOrganizationId
        ? forceOrganizationId
        : isSystemAdmin.value && isSystemAdminOrganisation.value
          ? "org_sysadmin"
          : selectedOrganization.value?.id;

    if (orgId) {
        headers.set("x-organization-id", orgId);
    }

    try {
        const response = await fetch(api(url), {
            ...fetchOptions,
            headers,
        });

        if (!response.ok) {
            let errorData: ApiErrorResponse;
            try {
                errorData = await response.json();
            } catch {
                throw new ApiError(`HTTP error! status: ${response.status}`, response.status);
            }

            if (response.status === 401 && !skipAuthRefresh) {
                console.info("Authentication error, attempting to refresh token...");
                const refreshSuccess = await authStore.refreshTokens();

                if (refreshSuccess) {
                    console.info("Token refreshed, retrying original request...");
                    return fetchWithOrgHeader(url, {
                        ...options,
                        skipAuthRefresh: true,
                    });
                }
            }

            throw new ApiError(
                errorData.message || `HTTP error! status: ${response.status}`,
                response.status,
                errorData,
            );
        }

        if (
            response.status === 204 ||
            (options.method === "DELETE" && response.status === 200) ||
            (returnUndefinedOn201 && response.status === 201)
        ) {
            return undefined as T;
        }

        return await response.json();
    } catch (error) {
        throw handleFetchError(error);
    }
};
