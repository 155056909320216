import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useAuthStore } from "@/stores/auth";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { toast } from "vue-sonner";

export async function authGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): Promise<void> {
    const authStore = useAuthStore();
    const { isSystemAdmin, isSystemAdminOrganisation } = useUserPrivileges();

    // Used variables
    let isAuthenticated = false;

    try {
        isAuthenticated = await authStore.checkAuthStatus();
    } catch (error) {
        console.error("Error checking authentication status:", error);
        // In case of error, consider the user as not authenticated
        isAuthenticated = false;

        // Notify the user
        if (from.name && from.name !== "login") {
            toast.error("Votre session a expiré. Veuillez vous reconnecter.");
        }
    }

    // Handle authentication
    if (to.meta.requiresAuth && !isAuthenticated) {
        // Save the current route to return after login
        return next({
            name: "login",
            query: {
                redirect: to.fullPath,
                // Add an error message if it's a forced logout
                ...(from.name && from.name !== "login" ? { authError: "session-expired" } : {}),
            },
        });
    }

    // Redirect authenticated users from login page
    if (to.name === "login" && isAuthenticated) {
        if (isSystemAdminOrganisation.value) {
            return next({ name: "organizations" });
        }
        return next({ name: "index" });
    }

    // Redirect system admin to organizations page
    if (to.name === "index" && isSystemAdmin.value && isSystemAdminOrganisation.value) {
        return next({ name: "organizations" });
    }

    // Allow navigation
    next();
}
