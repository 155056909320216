import { useAuthStore } from "@/stores/auth";
import { computed } from "vue";

export function useUserPrivileges() {
    const authStore = useAuthStore();

    const isHolder = computed(() => authStore.isHolder && authStore.userRole === "user");

    const userRole = computed(() => authStore.userRole);

    const isUserParticipant = computed(() => userRole.value === "user" && !authStore.isHolder);

    /**
     * description: If is holder/est-manager/org-manager/support/admin/system-admin
     */
    const hasElevatedPrivileges = computed(() => isHolder.value || (userRole.value && userRole.value !== "user"));

    const isAdmin = computed(() => userRole.value === "admin");

    const isOrgManager = computed(() => userRole.value === "org-manager");

    const isEstManager = computed(() => userRole.value === "est-manager");

    const isSupport = computed(() => userRole.value === "support");

    const isSystemAdmin = computed(() => authStore.isSystemAdmin);

    const isSystemAdminOrganisation = computed(() => authStore.selectedOrganization?.systemAdmin);

    /**
     * description: If is holder/est-manager/org-manager/admin/system-admin
     */
    const isMoreOrEqualToHolder = computed(
        () =>
            isHolder.value ||
            isEstManager.value ||
            isOrgManager.value ||
            isSupport.value ||
            isAdmin.value ||
            isSystemAdmin.value,
    );

    /**
     * description: If is est-manager/org-manager/admin/system-admin
     */
    const isMoreOrEqualToEstManager = computed(
        () => isEstManager.value || isOrgManager.value || isSupport.value || isAdmin.value || isSystemAdmin.value,
    );

    /**
     * description: If is org-manager/admin/support/system-admin
     */
    const isMoreOrEqualToOrgManager = computed(
        () => isOrgManager.value || isSupport.value || isAdmin.value || isSystemAdmin.value,
    );

    /**
     * description: If is support/admin/system-admin
     */
    const isMoreOrEqualToSupport = computed(() => isSupport.value || isAdmin.value || isSystemAdmin.value);

    const canManageUsers = computed(
        () => isAdmin.value || isOrgManager.value || isEstManager.value || isSupport.value || isSystemAdmin.value,
    );

    const canManageServices = computed(
        () =>
            isHolder.value ||
            isAdmin.value ||
            isOrgManager.value ||
            isEstManager.value ||
            isSupport.value ||
            isSystemAdmin.value,
    );

    return {
        userRole,
        isUserParticipant,
        isHolder,
        hasElevatedPrivileges,
        isEstManager,
        isOrgManager,
        isSupport,
        isAdmin,
        isSystemAdmin,
        isSystemAdminOrganisation,
        canManageUsers,
        canManageServices,
        isMoreOrEqualToHolder,
        isMoreOrEqualToEstManager,
        isMoreOrEqualToOrgManager,
        isMoreOrEqualToSupport,
    };
}
