<script setup lang="ts">
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { cn } from "@/lib/utils";
import { useAuthStore } from "@/stores/auth";
import type { LucideIcon } from "lucide-vue-next";
import {
    Briefcase,
    Calendar,
    CalendarPlusIcon,
    ClipboardList,
    ClockIcon,
    CoinsIcon,
    DollarSignIcon,
    Euro,
    EuroIcon,
    FileText,
    Home,
    HomeIcon,
    LocateFixed,
    LogOut,
    MailsIcon,
    Menu,
    PlusIcon,
    SearchIcon,
    SettingsIcon,
    Undo2Icon,
    User,
    UserPlusIcon,
    Users,
    UsersIcon,
} from "lucide-vue-next";
import { computed, ref } from "vue";
import type { RouteRecordName } from "vue-router";
import { useRoute, useRouter } from "vue-router";
import ProfileAvatar from "./ProfileAvatar.vue";
import CommandPalette from "./global/CommandPalette.vue";
import ScrollContainer from "./global/ScrollContainer.vue";
import SheetLink from "./global/links/SheetLink.vue";
import LegalAcceptanceDialog from "./legal/LegalAcceptanceDialog.vue";
import NotificationsWarnings from "./warnings/NotificationsWarnings.vue";

// Types
interface BaseLink {
    title: string;
    icon: LucideIcon;
    routeName: Exclude<RouteRecordName, null | undefined>;
    params?: Record<string, string>;
}

interface RegularLink extends BaseLink {
    isDropdown?: false;
}

interface DropdownLink extends BaseLink {
    isDropdown: true;
    subLinks: Array<{
        title: string;
        routeName: Exclude<RouteRecordName, null | undefined>;
        params?: Record<string, string>;
    }>;
}

type LinkType = RegularLink | DropdownLink;

// Composables
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const {
    isUserParticipant,
    isHolder,
    isEstManager,
    isMoreOrEqualToEstManager,
    isMoreOrEqualToOrgManager,
    isSupport,
    isSystemAdmin,
    isSystemAdminOrganisation,
} = useUserPrivileges();

// State
const isCommandOpen = ref(false);
const isSheetOpen = ref(false);
const isServicesDropdownOpen = ref(false);
const isMembersDropdownOpen = ref(false);

// Computed
const selectedOrganization = computed(() => authStore.selectedOrganization);
const userOrganization = computed(() => authStore.userOrganization);
const displayProfileLink = computed(() => selectedOrganization.value?.id === userOrganization.value?.id);

// Common navigation links
const homeLink = computed(
    (): RegularLink => ({
        title: "Accueil",
        icon: Home,
        routeName: "index",
    }),
);

const documentsLink = computed(
    (): RegularLink => ({
        title: "Documents",
        icon: FileText,
        routeName: "documents",
    }),
);

const ordersLink = computed(
    (): RegularLink => ({
        title: "Commandes",
        icon: Euro,
        routeName: "orders",
    }),
);

const recoveryTokensLink = computed(
    (): RegularLink => ({
        title: "Jetons de récupération",
        icon: CoinsIcon,
        routeName: "recovery-tokens",
    }),
);

const membersLink = computed(
    (): DropdownLink => ({
        title: "Membres",
        icon: Users,
        routeName: "members",
        isDropdown: true,
        subLinks: [
            { title: "Tous", routeName: "members" },
            { title: "Titulaires", routeName: "holders" },
            { title: "Participants", routeName: "participants" },
        ],
    }),
);

const configurationLink = computed(
    (): RegularLink => ({
        title: "Configuration",
        icon: SettingsIcon,
        routeName: isEstManager.value ? "configuration-establishments" : "configuration-organizations",
        params: isEstManager.value
            ? { id: authStore.currentEstablishmentId }
            : { id: selectedOrganization.value?.id || "" },
    }),
);

// Role-specific link collections
const getSystemAdminSupportLinks = computed((): LinkType[] => [
    { title: "Organisations", icon: HomeIcon, routeName: "organizations" },
    { title: "Contacter l'administration", icon: MailsIcon, routeName: "contact-support" },
]);

const getSystemAdminLinks = computed((): LinkType[] => [
    { title: "Organisations", icon: HomeIcon, routeName: "organizations" },
    { title: "Administrateurs et supports", icon: UsersIcon, routeName: "members" },
    { title: "Facturation", icon: EuroIcon, routeName: "billing" },
    configurationLink.value,
]);

const getRegularUserLinks = computed((): LinkType[] => [
    { ...homeLink.value, title: "Mon planning" },
    { title: "Réserver", icon: Briefcase, routeName: "catalog" },
    { title: "Mes activités", icon: ClipboardList, routeName: "services-attendance" },
    { title: "Famille", icon: Users, routeName: "delegations" },
    recoveryTokensLink.value,
    documentsLink.value,
    ordersLink.value,
]);

const getHolderLinks = computed((): LinkType[] => [
    homeLink.value,
    { title: "Services", icon: Briefcase, routeName: "services-holdings" },
    { title: "Participants", icon: Users, routeName: "attendees-holdings" },
    { title: "Heures prestées", icon: ClockIcon, routeName: "work-time" },
    documentsLink.value,
    ordersLink.value,
]);

const getEstablishmentManagerLinks = computed((): LinkType[] => [
    homeLink.value,
    { title: "Services", icon: Briefcase, routeName: "blueprints-managing" },
    membersLink.value,
    { title: "Périodes", icon: Calendar, routeName: "periods-managing" },
    { title: "Inscriptions", icon: CalendarPlusIcon, routeName: "reviews" },
    documentsLink.value,
    recoveryTokensLink.value,
    { title: "Comptabilité", icon: DollarSignIcon, routeName: "accountability" },
    configurationLink.value,
]);

const getOrganizationManagerLinks = computed((): LinkType[] => {
    const links: LinkType[] = [
        homeLink.value,
        { title: "Services", icon: Briefcase, routeName: "blueprints-managing" },
        membersLink.value,
        { title: "Périodes", icon: Calendar, routeName: "periods-managing" },
        { title: "Inscriptions", icon: CalendarPlusIcon, routeName: "reviews" },
        recoveryTokensLink.value,
        { title: "Établissements", icon: LocateFixed, routeName: "establishments" },
        { title: "Comptabilité", icon: DollarSignIcon, routeName: "accountability" },
        documentsLink.value,
        configurationLink.value,
    ];

    return isSupport.value ? links.filter(l => l.title !== "Comptabilité") : links;
});

// Main navigation links based on user role
const links = computed((): LinkType[] => {
    // System admin with support role && organisation admin
    if (isSystemAdminOrganisation.value && isSupport.value) {
        return getSystemAdminSupportLinks.value;
    }

    // System admin && organisation admin
    if (isSystemAdminOrganisation.value && isSystemAdmin.value) {
        return getSystemAdminLinks.value;
    }

    // Regular user (not holder)
    if (isUserParticipant.value && !isHolder.value) {
        return getRegularUserLinks.value;
    }

    // Holder user
    if (isHolder.value) {
        return getHolderLinks.value;
    }

    // Establishment manager
    if (isEstManager.value) {
        return getEstablishmentManagerLinks.value;
    }

    // Organization manager
    if (isMoreOrEqualToOrgManager.value && !isSystemAdminOrganisation.value) {
        return getOrganizationManagerLinks.value;
    }

    // Default links - just home, documents, orders
    return [homeLink.value, documentsLink.value, ordersLink.value];
});

// Additional action links
const getAdditionalActionsLinks = computed((): RegularLink[] => {
    if (!isMoreOrEqualToEstManager.value) return [];

    return [
        {
            title: "Créer un établissement",
            icon: PlusIcon,
            routeName: "establishment-create",
        },
        {
            title: "Créer un service",
            icon: PlusIcon,
            routeName: "service-create",
        },
        {
            title: "Créer une période",
            icon: CalendarPlusIcon,
            routeName: "period-create",
        },
        {
            title: "Ajouter un membre",
            icon: UserPlusIcon,
            routeName: "member-create",
        },
    ];
});

// Methods
const signOut = () => {
    authStore.signOut();
    router.push({ name: "login" });
};

const handleGoBackAdmin = () => {
    if (!isSystemAdmin.value) return;
    const previousOrganizationId = selectedOrganization.value?.id;
    authStore.exitSysAdminMode();
    router.push({ name: "organization-read", params: { id: previousOrganizationId } });
};

const toggleServicesDropdown = () => {
    isServicesDropdownOpen.value = !isServicesDropdownOpen.value;
};

const toggleMembersDropdown = () => {
    isMembersDropdownOpen.value = !isMembersDropdownOpen.value;
};

const closeSheet = () => {
    isSheetOpen.value = false;
};

// Active link helpers
const isLinkActive = (routeName: RouteRecordName, params?: Record<string, string>) => {
    if (routeName === "index") {
        return route.name === "index";
    }

    if (params) {
        return route.name === routeName && Object.entries(params).every(([key, value]) => route.params[key] === value);
    }

    return route.name === routeName;
};
</script>

<template>
    <aside
        :class="
            cn(
                'left-0 top-0 hidden h-screen w-72 flex-col items-start justify-between gap-6 px-0 py-10 text-white lg:flex',
                !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark',
            )
        ">
        <router-link
            :to="{ name: 'index' }"
            class="mx-auto inline-block w-full max-w-[100px]"
            title="Retour à l'accueil">
            <img src="/logo/light-small.png" class="mx-auto inline-block w-full max-w-[100px]" alt="Logo Rainbow Top" />
        </router-link>

        <!-- Main Navigation -->
        <ScrollContainer class="px-12">
            <nav>
                <ul class="flex grow flex-col gap-4">
                    <li v-for="link in links" :key="link.title">
                        <SheetLink
                            :link="link"
                            :is-services-dropdown-open="isServicesDropdownOpen"
                            :is-members-dropdown-open="isMembersDropdownOpen"
                            :toggle-services-dropdown="toggleServicesDropdown"
                            :toggle-members-dropdown="toggleMembersDropdown"
                            :is-link-active="isLinkActive"
                            @close="closeSheet" />
                    </li>
                </ul>
            </nav>
        </ScrollContainer>

        <!-- Footer -->
        <footer
            :class="
                cn('flex w-full grow flex-col items-start justify-end px-12', {
                    'gap-8': (!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation,
                    'gap-3': !isSystemAdminOrganisation && isSystemAdmin,
                })
            ">
            <!-- Notifications -->
            <div v-if="isMoreOrEqualToOrgManager && !isSystemAdminOrganisation" class="w-full">
                <NotificationsWarnings />
            </div>

            <!-- System Admin Info -->
            <div
                v-if="!isSystemAdminOrganisation && isSystemAdmin"
                :title="selectedOrganization?.id"
                class="flex w-full flex-col items-start gap-2">
                <div class="flex flex-col">
                    <p class="text-sm">Connecté à</p>
                    <p class="text-sm">{{ selectedOrganization?.name }}</p>
                </div>
            </div>

            <!-- Profile Link -->
            <div v-if="displayProfileLink" class="flex w-full items-center gap-4">
                <router-link
                    :to="{ name: 'profile' }"
                    class="group flex w-full flex-grow flex-row flex-nowrap items-center gap-4 text-white">
                    <ProfileAvatar class="group-hover:bg-secondary/80 transition-colors" />
                    <span
                        class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors group-hover:text-white/80">
                        Mon profil
                    </span>
                </router-link>
                <Button
                    variant="ghost"
                    size="icon"
                    @click="isCommandOpen = true"
                    class="hover:text-secondary text-white">
                    <SearchIcon :size="16" />
                </Button>
            </div>

            <!-- Action Buttons -->
            <Button
                v-if="(!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation"
                variant="secondary"
                @click="signOut"
                class="flex w-full items-center justify-center gap-2 py-2">
                <LogOut :size="20" />
                Se déconnecter
            </Button>
            <Button
                v-else-if="!isSystemAdminOrganisation && isSystemAdmin"
                variant="secondary"
                @click="handleGoBackAdmin"
                class="flex w-full items-center justify-center gap-2 py-2">
                <Undo2Icon :size="20" />
                Quitter
            </Button>
        </footer>
    </aside>

    <!-- Mobile Navigation Sheet -->
    <Sheet v-model:open="isSheetOpen">
        <div
            :class="
                cn(
                    'flex items-center justify-between p-4 lg:hidden',
                    !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark',
                )
            ">
            <router-link :to="{ name: 'index' }" class="flex items-center gap-2">
                <img
                    src="/logo/circle-white.png"
                    class="inline-flex size-10 items-center object-contain lg:hidden"
                    alt="Logo Rainbow Top" />
            </router-link>

            <div class="flex items-center gap-6">
                <SheetTrigger as-child>
                    <Button
                        class="flex shrink-0 items-center gap-2 bg-transparent px-0 lg:hidden"
                        @click="isSheetOpen = true">
                        Menu
                        <Menu :size="30" />
                        <span class="sr-only">Ouvrir le menu de navigation</span>
                    </Button>
                </SheetTrigger>

                <!-- Logout/Quit button -->
                <Button
                    v-if="(!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation"
                    variant="secondary"
                    @click="signOut"
                    class="flex items-center justify-center gap-2 py-2">
                    <LogOut :size="20" />
                </Button>
                <Button
                    v-else-if="!isSystemAdminOrganisation && isSystemAdmin"
                    variant="secondary"
                    @click="handleGoBackAdmin"
                    class="flex items-center justify-center gap-2 py-2">
                    <Undo2Icon :size="20" />
                </Button>
            </div>
        </div>
        <SheetContent
            side="left"
            :class="
                cn('bg-primary border-primary', !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark')
            ">
            <nav class="flex h-full flex-col gap-10 text-lg font-medium">
                <router-link :to="{ name: 'index' }" class="flex items-center gap-2">
                    <img
                        src="/logo/light-small.png"
                        class="mx-auto inline-block w-full max-w-[100px]"
                        alt="Logo Rainbow Top" />
                </router-link>
                <ul class="flex shrink flex-col gap-4">
                    <li v-for="link in links" :key="link.title">
                        <SheetLink
                            :link="link"
                            :is-services-dropdown-open="isServicesDropdownOpen"
                            :is-members-dropdown-open="isMembersDropdownOpen"
                            :toggle-services-dropdown="toggleServicesDropdown"
                            :toggle-members-dropdown="toggleMembersDropdown"
                            :is-link-active="isLinkActive"
                            @close="closeSheet" />
                    </li>
                </ul>

                <footer class="flex grow flex-col items-start justify-end gap-4">
                    <router-link
                        v-if="displayProfileLink"
                        :to="{ name: 'profile' }"
                        class="group flex flex-row flex-nowrap items-center gap-2 text-white"
                        @click="closeSheet">
                        <component
                            :is="User"
                            class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full bg-transparent p-2 transition-colors" />
                        <span
                            class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
                            Mon profil
                        </span>
                    </router-link>

                    <Button
                        variant="secondary"
                        @click="signOut"
                        class="flex w-full items-center justify-center gap-2 py-2">
                        <LogOut :size="20" />
                        <p
                            class="group-hover:decoration-primary text-lg font-medium underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
                            Se déconnecter
                        </p>
                    </Button>
                </footer>
            </nav>
        </SheetContent>
    </Sheet>

    <!-- Legal Acceptance Dialog -->
    <LegalAcceptanceDialog />

    <!-- Command Palette Component -->
    <CommandPalette
        v-model:is-command-open="isCommandOpen"
        :links="links"
        :additional-actions-links="getAdditionalActionsLinks"
        @sign-out="signOut" />
</template>
