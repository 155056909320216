<template>
    <div v-if="isReady" class="flex h-screen flex-col">
        <component :is="$route.meta.layout">
            <RouterView :key="route.fullPath" />
        </component>
        <Toaster close-button />
    </div>
    <div v-else class="flex items-center justify-center lg:h-screen">
        <p>Loading...</p>
    </div>
</template>

<script setup lang="ts">
import { Toaster } from "@/components/ui/sonner";
import { onMounted, ref } from "vue";
import { RouterView, useRoute } from "vue-router";

const route = useRoute();

const isReady = ref(false);

onMounted(async () => {
    isReady.value = true;
});
</script>
