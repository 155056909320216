<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useAuthStore } from "@/stores/auth";
import { useDocumentsStore } from "@/stores/documents";
import { useMeStore } from "@/stores/me";
import { Loader2 } from "lucide-vue-next";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { toast } from "vue-sonner";

const { isMoreOrEqualToSupport } = useUserPrivileges();

const authStore = useAuthStore();
const { selectedOrganization, user } = storeToRefs(authStore);

const meStore = useMeStore();

const documentsStore = useDocumentsStore();
const { publicDocuments } = storeToRefs(documentsStore);

const legalMentionsDocument = computed(() => {
    return publicDocuments.value.find(doc => doc.category === "legal.mentions");
});
const legalMentionsDocumentDownloadUrl = computed(() => {
    if (!legalMentionsDocument.value) {
        return "";
    }

    return documentsStore.getDocumentDownloadUrl({
        id: legalMentionsDocument.value?.id,
        orgId: selectedOrganization.value?.id,
    });
});

const legalRoiDocument = computed(() => {
    return publicDocuments.value.find(doc => doc.category === "legal.roi");
});
const legalRoiDocumentDownloadUrl = computed(() => {
    if (!legalRoiDocument.value) {
        return "";
    }

    return documentsStore.getDocumentDownloadUrl({
        id: legalRoiDocument.value?.id,
        orgId: selectedOrganization.value?.id,
    });
});

const isLoading = ref(false);
const isOpen = ref(false);

// Compute if the dialog should be shown
const shouldShowDialog = computed(() => {
    if (isMoreOrEqualToSupport.value) {
        return false;
    }

    if (!user.value) {
        return false;
    }

    return user.value && (!user.value.hasAcceptedROIAt || !user.value.hasAcceptedLegalMentionsAt);
});

// Watch for changes in the user's ROI acceptance status
watch(shouldShowDialog, newValue => {
    if (newValue) {
        isOpen.value = true;
    }
});

// Check initial state on mount
onMounted(async () => {
    if (shouldShowDialog.value) {
        isOpen.value = true;
    }

    if (publicDocuments.value.length === 0) {
        isLoading.value = true;
        try {
            await documentsStore.fetchPublicDocuments();
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            isLoading.value = false;
        }
    }
});

const handleAcceptROI = async () => {
    try {
        isLoading.value = true;
        await meStore.acceptROI();
        isOpen.value = false;
    } catch (error: any) {
        toast.error(error.message);
    } finally {
        isLoading.value = false;
    }
};

const handleAcceptLegalMentions = async () => {
    try {
        isLoading.value = true;
        await meStore.acceptLegalMentions();
        isOpen.value = false;
    } catch (error: any) {
        toast.error(error.message);
    } finally {
        isLoading.value = false;
    }
};

async function handleAcceptLegals() {
    await handleAcceptROI();
    await handleAcceptLegalMentions();
}

// Prevent dialog from being closed
const handleOpenChange = (value: boolean) => {
    if (!shouldShowDialog.value) {
        isOpen.value = value;
    }
};
</script>

<template>
    <Dialog :open="isOpen" @update:open="handleOpenChange">
        <DialogContent :closeable="false" hideClose>
            <DialogHeader>
                <DialogTitle><h6>Contrats légaux</h6></DialogTitle>
                <DialogDescription>
                    Avant de continuer, vous devez accepter le règlement d'ordre intérieur et les mentions légales.
                </DialogDescription>
            </DialogHeader>

            <div class="grid gap-4 py-4">
                <p class="body">
                    En acceptant
                    <span v-if="legalRoiDocumentDownloadUrl">
                        le
                        <a
                            :href="legalRoiDocumentDownloadUrl"
                            target="_blank"
                            class="hover:text-primary/70 italic underline transition-colors duration-200 ease-out">
                            règlement d'ordre intérieur
                        </a>
                        et
                    </span>
                    les
                    <a
                        :href="legalMentionsDocumentDownloadUrl"
                        target="_blank"
                        class="hover:text-primary/70 italic underline transition-colors duration-200 ease-out">
                        mentions légales,</a
                    > vous confirmez avoir lu et compris ceux-ci.
                </p>
            </div>

            <DialogFooter>
                <Button class="w-full" @click="handleAcceptLegals" :disabled="isLoading">
                    <Loader2 v-if="isLoading" class="mr-2 h-4 w-4 animate-spin" />
                    J'accepte le règlement d'ordre intérieur et les mentions légales
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
