<script setup lang="ts">
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command";
import { useMagicKeys } from "@vueuse/core";
import { LogOut, type LucideProps } from "lucide-vue-next";
import { computed, watch } from "vue";
import type { RouteLocationRaw, RouteRecordName } from "vue-router";
import { useRouter } from "vue-router";

// Types
interface CommandItem {
    title: string;
    routeName: Exclude<RouteRecordName, null | undefined>;
    params?: Record<string, string>;
    icon: LucideProps;
}

interface Link {
    title: string;
    icon: any;
    routeName: Exclude<RouteRecordName, null | undefined>;
    params?: Record<string, string>;
    isDropdown?: boolean;
    subLinks?: Array<{
        title: string;
        routeName: Exclude<RouteRecordName, null | undefined>;
        params?: Record<string, string>;
    }>;
}

// Props
const props = defineProps<{
    links: Link[];
    additionalActionsLinks?: Link[];
}>();

// Emits
const emit = defineEmits<{
    (e: "sign-out"): void;
}>();

// State
const isCommandOpen = defineModel<boolean>("isCommandOpen", { required: false });

// Computed
const allCommandItems = computed((): CommandItem[] => {
    return props.links.flatMap(link => {
        if (link.isDropdown) {
            return (
                link.subLinks?.map(subLink => ({
                    title: `${link.title} - ${subLink.title}`,
                    routeName: subLink.routeName,
                    params: subLink.params,
                    icon: link.icon,
                })) || []
            );
        }
        return [
            {
                title: link.title,
                routeName: link.routeName,
                params: link.params,
                icon: link.icon,
            },
        ];
    });
});

// Methods
const router = useRouter();

const toggleCommand = () => {
    isCommandOpen.value = !isCommandOpen.value;
};

const navigateToItem = (routeName: RouteRecordName, params?: Record<string, string>) => {
    router.push({ name: routeName, params } as RouteLocationRaw);
    isCommandOpen.value = false;
};

const signOut = () => {
    emit("sign-out");
    isCommandOpen.value = false;
};

// Lifecycle hooks
const keys = useMagicKeys();
const CmdK = keys["Cmd+K"];

watch(CmdK, v => {
    if (v) {
        toggleCommand();
    }
});
</script>

<template>
    <div>
        <!-- Command Dialog -->
        <CommandDialog v-model:open="isCommandOpen">
            <Command>
                <CommandInput placeholder="Tapez une commande ou recherchez..." />
                <CommandList>
                    <CommandEmpty>Aucun résultat trouvé.</CommandEmpty>
                    <CommandGroup heading="Suggestions">
                        <CommandItem
                            v-for="item in allCommandItems"
                            :key="item.routeName"
                            :value="item.title"
                            @select="() => navigateToItem(item.routeName, item.params)">
                            <component :is="item.icon" class="mr-2 h-4 w-4" />
                            <span>{{ item.title }}</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator />
                    <CommandGroup heading="Actions">
                        <CommandItem
                            v-for="link in additionalActionsLinks"
                            :key="link.routeName"
                            :value="link.title"
                            @select="() => navigateToItem(link.routeName)">
                            <component :is="link.icon" class="mr-2 h-4 w-4" />
                            <span>{{ link.title }}</span>
                        </CommandItem>
                        <CommandItem @select="signOut" value="Se déconnecter">
                            <LogOut class="mr-2 h-4 w-4" />
                            <span>Se déconnecter</span>
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </CommandDialog>
    </div>
</template>
